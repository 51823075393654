/* eslint-disable camelcase */
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from '@emotion/styled';

import { Button } from '@xchange/uikit';
import { InputCurrency, InputPercentage } from 'components';
import { validateInputPercentage } from 'utils/validation';

interface DefaultCommissionsFormProps {
  data?: CommissionsData;
  buttonText?: string;
  onSubmit: (values: CommissionsData) => void;
}

const DefaultCommissionsForm: React.FC<DefaultCommissionsFormProps> = ({
  data = {},
  buttonText = 'Save',
  onSubmit
}) => {
  const {
    control,
    formState,
    handleSubmit,
  } = useForm({
    defaultValues: data
  });

  return (
    <StyledDefaultCommissionsForm
      className="commissions-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        control={control}
        name="sponsoring_broker_fee"
        defaultValue={0}
        render={({ field, formState: { errors } }) => (
          <InputCurrency
            label="Monthly Sponsoring Broker Fee"
            placeholder="Enter amount"
            error={errors.sponsoring_broker_fee?.message}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="technology_fee"
        defaultValue={0}
        render={({ field, formState: { errors } }) => (
          <InputCurrency
            label="Monthly Technology Fee"
            placeholder="Enter amount"
            error={errors.technology_fee?.message}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="desk_fee"
        defaultValue={0}
        render={({ field, formState: { errors } }) => (
          <InputCurrency
            label="Monthly Desk Fee"
            placeholder="Enter amount"
            error={errors.desk_fee?.message}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        rules={{
          validate: validateInputPercentage
        }}
        name="sell_agreements_fee"
        defaultValue={0}
        render={({ field, formState: { errors } }) => (
          <InputPercentage
            label="Per-Transaction Sell Agreements Fee"
            placeholder="Enter percentage"
            error={errors.sell_agreements_fee?.message}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        rules={{
          validate: validateInputPercentage
        }}
        name="lease_agreements_fee"
        defaultValue={0}
        render={({ field, formState: { errors } }) => (
          <InputPercentage
            label="Per-Transaction Lease Agreements Fee"
            placeholder="Enter percentage"
            error={errors.lease_agreements_fee?.message}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="transaction_coordination_fee"
        defaultValue={0}
        render={({ field, formState: { errors } }) => (
          <InputCurrency
            label="Per-Transaction Coordination Fee"
            placeholder="Enter amount"
            error={errors.transaction_coordination_fee?.message}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="risk_management_fee"
        defaultValue={0}
        render={({ field, formState: { errors } }) => (
          <InputCurrency
            label="Per-Transaction Risk-Management Fee"
            placeholder="Enter amount"
            error={errors.risk_management_fee?.message}
            {...field}
          />
        )}
      />
      <Button disabled={formState.isSubmitting} className="commissions-form__btn">
        {buttonText}
      </Button>
    </StyledDefaultCommissionsForm>
  );
};

export default React.memo(DefaultCommissionsForm);

const StyledDefaultCommissionsForm = styled.form`
  display: grid;
  grid-gap: 16px;
  .commissions-form {
    &__btn {
      margin-top: 8px;
    }
  }
`;
