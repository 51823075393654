import React from 'react';

import styled from '@emotion/styled';
import Button from 'components/Button';
import Icon from 'components/Icon';

const Tag = ({ children, onDelete, ...props }) => (
  <StyledTag {...props} className="tag">
    {children}
    {onDelete && (
      <Button simple>
        <Icon name="mini-x" onClick={onDelete} size={8} />
      </Button>
    )}
  </StyledTag>
);

export default React.memo(Tag);

const StyledTag = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px 4px 4px;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid #000000;
  border-radius: ${props => props.theme.misc.borderRadius};

  .icon {
    margin-left: 8px;
    fill: ${props => props.theme.colors.red};
  }
`;
