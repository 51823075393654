import React, { useRef, useState } from 'react';

import styled from '@emotion/styled';
import { Spinner, Icon, Input, InputProps } from '@xchange/uikit';

export interface FileInputProps extends Omit<InputProps, 'onChange'> {
  ref?: any;
  value?: File;
  inputText?: string;
  onChange?: (file: File | File[]) => void;
  onFileUpload?: (value: File | File[]) => void;
  onFileRemove?: (value: number) => void;
  files?: Array<{ id: string; name: string; } | string>;
  accept?: string;
  showSpinner?: boolean;
}

export enum FileInputACCEPT {
  IMAGES = 'image/*',
  ALL = ''
}

const FileInput: React.FC<FileInputProps> = React.forwardRef(
  (
    {
      value,
      inputText,
      onChange,
      onFileUpload,
      onFileRemove,
      files = [],
      accept,
      showSpinner: showSpinnerProps,
      ...props
    },
    refProp
  ) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);

    const handleFileInputChange = async e => {
      if (showSpinnerProps) setShowSpinner(true);

      const file: File = e.target.files[0];
      if (!file) return;
      fileInputRef.current!.value = '';
      if (onChange) await onChange(file);
      if (onFileUpload) await onFileUpload(file);
      if (showSpinnerProps) setShowSpinner(false);
    };

    return (
      <StyledFileInput className="file-input">
        <Input
          icon={showSpinner ? <Spinner size={24} /> : 'fc-file'}
          value={inputText || value?.name || ''}
          onClick={() => fileInputRef.current!.click()}
          {...props}
          ref={refProp}
          readOnly
        />
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleFileInputChange}
          accept={accept}
          style={{ display: 'none' }}
          disabled={showSpinner}
        />
        {files && (
          <div className="file-input__files">
            {files.map((file, idx) => (
              <span key={idx} className="file-input__file">
                <Icon name="fc-file" />
                &nbsp;&nbsp;
                <span className="file-input__file__name">
                  {typeof file === 'string' ? file : file.name}
                </span>&nbsp;&nbsp;
                {onFileRemove && (
                  <Icon name="mini-x" size={8} onClick={() => onFileRemove(idx)} />
                )}
              </span>
            ))}
          </div>
        )}
      </StyledFileInput>
    );
  }
);

export default FileInput;

const StyledFileInput = styled.div`
  .file-input {
    &__file {
      margin: 8px 8px 0 0;
      display: inline-flex;
      align-items: center;
      padding: 4px;
      font-size: 12px;
      line-height: 16px;
      border: 1px solid #000;
      border-radius: ${props => props.theme.misc.borderRadius};
      &__name {
        max-width: 112px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .icon-mini-x {
        fill: ${props => props.theme.colors.red};
        cursor: pointer;
      }
    }
  }
  .input {
    width: 100%;
  }
  .spinner {
    position: absolute;
    right: 8px;
    top: 8px;
  }
`;
