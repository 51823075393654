import React from 'react';
import { Editor as TinyMCE, IAllProps as EditorProps } from '@tinymce/tinymce-react';

import { info } from 'sitedata';
import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';

const contentStyles = `
  body {
    box-sizing: border-box;
    font-family: 'Times New Roman', Times, serif;
    margin: 0 12px;
  }
`;

const TextEditor = ({
  value,
  onChange,
  height
}) => {
  const layout = useLayout();
  const editorHeight = layout === 'mobile' ? height.mobile || 364 : height.desktop || 144;

  const editorConfig: EditorProps = {
    init: {
      body_id: 'tinymce_xp_body_id',
      content_style: contentStyles,
      menubar: false,
      statusbar: false,
      toolbar: `undo redo | formatselect | italic bold |
      alignleft aligncenter alignright alignjustify | outdent indent `,
      spellchecker_language: 'en',
      browser_spellcheck: true,
      height: `${editorHeight}px`
    }
  };

  return (
    <StyledEditor className="editor">
      <div id="toolbar_container" />
      <TinyMCE
        id="tinymce_xp_body_id"
        apiKey={info.GATSBY_TINYMCE_APIKEY}
        value={value}
        onEditorChange={onChange}
        data-cy="tinymce_editor"
        {...editorConfig}
      />
    </StyledEditor>
  );
};

export default TextEditor;

const StyledEditor = styled.div`
  margin: 16px 0 12px 0;

  .tox-tinymce {
    box-sizing: border-box;
    margin: auto;
    border-color: ${props => props.theme.colors.alto};
    border-radius: ${props => props.theme.misc.borderRadius};
  }

  .tox-editor-header {
    border-bottom: 1px solid ${props => props.theme.colors.alto};
  }

  .tox .tox-tbtn {
    height: 24px;

    &:not(.tox-tbtn--select) {
      width: 24px;
    }

    svg {
      transform: scale(0.75);
    }
  }
`;
