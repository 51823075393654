import { fetchWithToken } from 'utils/fetch';
import { checkSuccess, urlEncodeBody } from 'utils/requests';

export async function getVendors(): Promise<Vendor[]> {
  const response = await fetchWithToken('/vendors');
  const data = await response.json();

  checkSuccess(data);

  return data;
}

export async function createVendor(vendor: Vendor): Promise<void> {
  await fetchWithToken(`/vendors`, {
    method: 'POST',
    body: urlEncodeBody(mapVendor(vendor))
  });
}

export async function updateVendor(vendor: Vendor): Promise<void> {
  await fetchWithToken(`/vendors/${vendor.id}`, {
    method: 'PUT',
    body: urlEncodeBody(mapVendor(vendor))
  });
}

export async function deleteVendor(vendorId: number): Promise<void> {
  await fetchWithToken(`/vendors/${vendorId}`, {
    method: 'DELETE'
  });
}

export async function getUserVendors(): Promise<{
  attorneys: Vendor[];
  lenders: Vendor[];
}> {
  const response = await fetchWithToken('/profile/vendors');
  const data = await response.json();

  checkSuccess(data);

  return {
    attorneys: data.Attorney?.preferred || [],
    lenders: data.Lender?.preferred || []
  };
}

export async function updateUserVendors(
  vendors: { type: string; preferred: [number] }[]
): Promise<any> {
  const body = JSON.stringify({ data: JSON.stringify(vendors) });
  const response = await fetchWithToken('/profile/vendors', {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body
  });
  const data = await response.json();

  checkSuccess(data);

  return data;
}

const mapVendor = (vendor: Vendor) => ({
  name: vendor.name,
  email: vendor.email,
  company_name: vendor.company_name,
  category: vendor.category,
  address: vendor.address,
  address1: vendor.address1,
  address2: vendor.address2,
  city: vendor.city,
  state: vendor.state,
  zip: vendor.zip,
  phone: vendor.phone,
  fax: vendor.fax
});
