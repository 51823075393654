/* eslint-disable import/extensions */
import { formatCurrency } from './rifmFormatters';
import { REGEXP } from 'consts';

const DISCOUNT_COEFFICIENT = process.env.GATSBY_DISCOUNT_COEFFICIENT || 50;

// returns city, state, postal code
export const getListingAddress = listing => {
  const { address1, address2, city, state, zip } = listing;
  const buildingAddress = [address1, address2].filter(Boolean).join(' ');
  const stateAndZip = [state, zip].filter(Boolean).join(' ');

  return [buildingAddress, city, stateAndZip].filter(Boolean).join(', ');
};

export const getListingUrl = (id) => {
  return `/listing/${id}/`;
};

export const calculateDiscount = price => {
  if (!price) return '';
  const result = (price * 0.025 * Number(DISCOUNT_COEFFICIENT)) / 100;
  return formatCurrency(Math.round(result / 100) * 100);
};

export const getEarnestMoneyTotal = (...args) => {
  const earnestMoneySum = args
    .filter(Boolean)
    .map(Number)
    .reduce((accum, current) => accum + current, 0);
  return earnestMoneySum ? formatCurrency(earnestMoneySum) : '-';
};

export const getListingImageProps = (src?: string | { url: string }) => {
  if (typeof src === 'string') {
    return src.includes('http') ? { src } : { cloudFront: src };
  }
  if (src?.url) return { src: src.url };
  return '';
};

export const isMlsListing = (listingId = '') => REGEXP.MLS_ID.test(listingId);
