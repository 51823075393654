import { useEffect, useCallback } from 'react';

export default function useKeyPress(
  targetKey: string | string[],
  callback: (event: KeyboardEvent) => void
) {
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (Array.isArray(targetKey) ? targetKey.includes(e.key) : e.key === targetKey) callback(e);
    },
    [callback, targetKey]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
}
