import React, { useState } from 'react';
import noImage from 'assets/images/placeholder_no_image.jpg';
import clsx from 'clsx';
import { info } from 'sitedata';

const cludfrontApi = info.cloudfrontUrl;
interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  resize?: string;
  srcList?: string | string[];
  size?: string;
  error?: string;
  dimensions?: string;
  cloudFront?: string;
}

const Image: React.FC<ImageProps> = ({
  src: scrProp,
  cloudFront,
  size = '400x300',
  alt = '',
  error = noImage,
  className,
  ...props
}) => {
  const [isError, setIsError] = useState(false);
  const src = cloudFront ? `${cludfrontApi}/fit-in/${size}/${cloudFront}` : scrProp;

  if (isError || !src)
    return <img {...props} src={error} alt={src} className={clsx('img-error', className)} />;

  return (
    <img
      src={src}
      alt={alt}
      className={clsx('img', className)}
      onError={() => setIsError(true)}
      {...props}
    />
  );
};

export default Image;
