import React from 'react';
import { useRifm } from 'rifm';

import Input, { InputProps } from 'components/Input';

const numberAccept = /[\d.]+/g;

const parseNumber = string => (string.match(numberAccept) || []).join('');

const formatFloatingPointNumber = (value, maxDigits) => {
  const parsed = parseNumber(value);
  const [head, tail] = parsed.split('.');
  const scaledTail = tail != null ? tail.slice(0, maxDigits) : '';

  const number = Number.parseFloat(`${head}.${scaledTail}`);

  if (Number.isNaN(number)) {
    return '';
  }

  const formatted = String(number);

  if (parsed.includes('.')) {
    const [formattedHead] = formatted.split('.');
    const formattedTail =
      scaledTail !== '' && scaledTail[maxDigits - 1] === '0' ? scaledTail.slice(0, -1) : scaledTail;

    return `${formattedHead}.${formattedTail}`;
  }
  return formatted;
};

const formatPercentage = string => (string ? `${formatFloatingPointNumber(string, 2)}%` : '');

interface InputPercentageProps extends Omit<InputProps, 'onChange'> {
  value?: number | string;
  onChange: (value: number | '') => void;
}

const InputPercentage: React.FC<InputPercentageProps> = React.forwardRef(
  ({ value = '', onChange, ...props }, ref) => {
    const rifm = useRifm({
      accept: /[\d.$]/g,
      format: v => formatPercentage(v),
      value: `${value}`,
      onChange: value => onChange(parseNumber(value))
    });

    return <Input ref={ref} value={rifm.value} onChange={rifm.onChange} {...props} />;
  }
);

export default InputPercentage;
