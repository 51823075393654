const parseNumber = string => (string.match(/[\d.]+/g) || []).join('');

const formatFloatingPointNumber = (value, maxDigits) => {
  const parsed = parseNumber(value);
  const [head, tail] = parsed.split('.');
  // Avoid rounding errors at toLocaleString as when user enters 1.239 and maxDigits=2 we
  // must not to convert it to 1.24, it must stay 1.23
  const scaledTail = tail != null ? tail.slice(0, maxDigits) : '';
  const number = Number.parseFloat(`${head}.${scaledTail}`);

  if (Number.isNaN(number)) return '';

  const formatted = number.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDigits
  });

  if (parsed.includes('.')) {
    const [formattedHead] = formatted.split('.');

    const formattedTail =
      scaledTail !== '' && scaledTail[maxDigits - 1] === '0' ? scaledTail.slice(0, -1) : scaledTail;

    return `${formattedHead}.${formattedTail}`;
  }
  return formatted;
};

export const formatCurrency = (value?: string | number) => {
  if (value === 0) return `$0`;
  return value ? `$${formatFloatingPointNumber(String(value), 2)}` : '';
};
