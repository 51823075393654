import React, { useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import clsx from 'clsx';
import styled from '@emotion/styled';
import useKeyPress from 'hooks/useKeyPress';
import useOutsideClick from 'hooks/useOutsideClick';
import { Input, InputProps, Spinner } from '@xchange/uikit';

export type AutocompleteOption = {
  id?: string | number;
  text: React.ReactNode | JSX.Element;
  value?: any;
  disabled?: boolean;
};

export interface InputAutocompleteOptionsProps extends React.HTMLAttributes<HTMLDivElement> {
  items?: AutocompleteOption[];
  minLength?: number;
  onOptionSelect: (option: AutocompleteOption) => void;
}

const InputAutocompleteOptions: React.FC<InputAutocompleteOptionsProps> = ({
  items,
  onOptionSelect
}) => {
  const [focus, setFocus] = useState<number>();

  useKeyPress(['ArrowDown', 'ArrowUp'], e => {
    e.preventDefault();
    const options = document.getElementsByClassName('input-autocomplete__option');
    const idxOffset = e.key === 'ArrowDown' ? 1 : -1;
    const optionToFocusIdx = focus === undefined ? 0 : focus + idxOffset;
    const optionToFocus = options[optionToFocusIdx] as HTMLButtonElement;
    if (optionToFocus) {
      optionToFocus.focus();
      setFocus(optionToFocusIdx);
    }
  });

  return (
    <div className="input-autocomplete__options-list">
      {items?.length === 0 && <p className="no-options">Empty</p>}
      {items?.map((item, idx) => (
        <button
          className={clsx('input-autocomplete__option', { disabled: item.disabled })}
          // eslint-disable-next-line react/no-array-index-key
          key={`${idx} ${item.id}`}
          onClick={() => onOptionSelect(item)}
          tabIndex={0}>
          {item.text}
        </button>
      ))}
    </div>
  );
};

export interface InputAutocompleteProps extends Omit<InputProps, 'onChange' | 'as'> {
  containerProps?: React.HTMLAttributes<HTMLInputElement>;
  showSpinner?: boolean;
  onChange?: (value: React.ReactNode | JSX.Element) => void;
  onOptionSelect?: (option: AutocompleteOption) => void;
  getOptions: (text: string) => Promise<AutocompleteOption[] | undefined>;
}

const InputAutocomplete: React.FC<InputAutocompleteProps> = React.forwardRef(
  (
    {
      className,
      style,
      onChange,
      getOptions,
      onOptionSelect,
      minLength = 3,
      showSpinner: showSpinnerProps,
      ...props
    },
    refProp
  ) => {
    const containerRef = useRef(null);
    const refHook = useRef(null);
    const ref: any = refProp || refHook;
    const [options, setOptions] = useState<AutocompleteOption[]>();
    const [showOptions, setShowOptions] = useState(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);

    const [fetchOptions] = useDebouncedCallback(async (text: string) => {
      if (showSpinnerProps) setShowSpinner(true);
      const options = await getOptions(text);
      setShowSpinner(false);
      if (options) {
        setOptions(options);
        setShowOptions(true);
      }
    }, 300);

    useOutsideClick(containerRef.current, () => {
      if (showOptions) setShowOptions(false);
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (value.length >= minLength) fetchOptions(value.toLowerCase());
      onChange?.(value);
    };

    const handleOptionSelect = (option: AutocompleteOption) => {
      if (onChange) onChange(option.text);
      else if (ref.current?.value) ref.current.value = option.text;
      setShowOptions(false);
      onOptionSelect?.(option);
    };

    const handleFocus = e => {
      e.target.setAttribute('autocomplete', 'off');
    };

    return (
      <StyledInputAutocomplete
        ref={containerRef}
        className={clsx('input-autocomplete', className)}
        style={style}>
        <Input
          onFocus={handleFocus}
          ref={ref}
          {...props}
          onChange={handleInputChange}
          icon={showSpinner ? <Spinner size={24} /> : null}
        />
        {showOptions && (
          <InputAutocompleteOptions items={options} onOptionSelect={handleOptionSelect} />
        )}
      </StyledInputAutocomplete>
    );
  }
);

export default InputAutocomplete;

const StyledInputAutocomplete = styled.div`
  display: inline-block;
  position: relative;

  .input {
    width: 100%;
  }

  .spinner {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .input-autocomplete__options-list {
    position: absolute;
    box-sizing: border-box;
    z-index: 1000;
    margin: 4px 0;
    width: 100%;
    max-height: 252px;
    min-height: 32px;
    overflow: auto;
    padding: 0;
    box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
    border-radius: ${props => props.theme.misc.borderRadius};
    background: white;
    list-style: none;

    .no-options {
      padding: 8px 12px;
    }

    .input-autocomplete__option {
      font-size: 12px;
      line-height: 18px;
      height: auto;
      padding: 8px 12px;
      box-sizing: border-box;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border: none;
      background: none;
      width: 100%;
      text-align: left;
      outline: none;

      &.disabled {
        background: #f7f7f7;
        pointer-events: none;
      }

      &:hover,
      &:focus {
        color: ${props => props.theme.colors.grayDark};
        fill: ${props => props.theme.colors.grayDark};
      }
    }
  }
`;
