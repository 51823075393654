import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import ResizeObserver from 'resize-observer-polyfill';

import useSitedataAssets from 'hooks/useSitedataAssets';
import useLayout from 'hooks/useLayout';
import Button from '../Button';
import Icon from '../Icon';
import SideMenu from '../SideMenu';
import HeaderAvatarMenu from '../HeaderAvatarMenu';

interface EditorLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  content?: React.ReactNode;
  sidebar?: React.ReactNode;
  header?: React.ReactNode;
  stickyHeader?: React.ReactNode;
}

const pdfViewerWidth = 880;

const EditorLayout: React.FC<EditorLayoutProps> = ({ content, sidebar, header, stickyHeader, ...props }) => {
  const layout = useLayout();
  const { logo } = useSitedataAssets();
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState<number>();

  useEffect(() => {
    if (wrapperRef.current === null || layout !== 'mobile') return;

    const handleResize = () => {
      const width = wrapperRef.current?.getBoundingClientRect().width;
      if (width) setScale(width / pdfViewerWidth);
    };

    const ro = new ResizeObserver(handleResize);
    ro.observe(wrapperRef.current);

    // eslint-disable-next-line consistent-return
    return () => {
      ro.disconnect();
    };
  }, []);

  return (
    <>
      <StyledEditorLayout {...props} ref={wrapperRef}>
        <header>
          {layout === 'desktop' && (
            <div className="logo-container">
              {logo && <img src={logo} alt="logo" className="logo" />}
            </div>
          )}
          {layout !== 'desktop' && (
            <Button className="menu-button" simple onClick={() => setSideMenuIsOpen(v => !v)}>
              <Icon name="menu-hamburger" />
            </Button>
          )}
          {header}
          <HeaderAvatarMenu />
        </header>
        {stickyHeader && (
          <div className="sticky-header">{stickyHeader}</div>
        )}
        {sidebar && <aside className="sidebar">{sidebar}</aside>}
        <div
          className="content"
          style={{ transform: `scale(${scale}` }}
        >
          {content}
        </div>
      </StyledEditorLayout>
      {layout !== 'desktop' && (
        <StyledSideMenu
          hideCloseButton
          position="left"
          open={sideMenuIsOpen}
          onClose={() => setSideMenuIsOpen(false)}>
          {sidebar}
        </StyledSideMenu>
      )}
    </>
  );
};

export default React.memo(EditorLayout);

const StyledEditorLayout = styled.div`
  display: grid;
  grid-template-areas:
    'area-header area-header'
    'area-sticky area-sticky'
    'area-sidebar area-content';
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  height: 100vh;
  position: relative;

  header {
    grid-area: area-header;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.colors.seashell};
    height: 80px;
    padding: 0 30px;

    .logo-container {
      width: 253px;
      img {
        max-width: 100%;
      }
    }

    .avatar-button {
      margin-left: auto;
    }
  }

  aside {
    grid-area: area-sidebar;
    border-right: 1px solid ${props => props.theme.colors.seashell};
    width: 253px;
    padding-left: 30px;
  }

  .sticky-header {
    grid-area: area-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .content {
    grid-area: area-content;
    padding: 24px 30px;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-template-areas:
      'area-header'
      'area-sticky'
      'area-content';
    grid-template-columns: 1fr;

    aside {
      width: 253px;
      position: absolute;
      height: 100%;
      left: -100%;
    }

    .content {
      width: 100%;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    overflow: hidden;
    header {
      height: 56px;
      width: 100vw;
      padding: 0 10px;
    }

    .sticky-header {
      width: 100vw;
    }

    .content {
      transform-origin: top left;
    }
  }
`;

const StyledSideMenu = styled(SideMenu)`
  background: none;
  animation: none;
  z-index: 1001;

  .side-menu {
    box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  }
`;
