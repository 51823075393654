import React from 'react';
import { useRifm } from 'rifm';

import { formatCurrency } from 'utils/rifmFormatters';
import Input, { InputProps } from 'components/Input';

const currencyToNumber = (str: string) => Number(String(str).replaceAll(/[^\d]/g, '')) || '';

interface CurrencyInputProps extends Omit<InputProps, 'onChange'> {
  value?: number | string;
  onChange: (value: number | '') => void;
}

const InputCurrency: React.FC<CurrencyInputProps> = React.forwardRef(
  ({ value = '', onChange, ...props }, ref) => {
    const rifm = useRifm({
      accept: /\d/g,
      value: String(value),
      onChange: value => onChange(currencyToNumber(value)),
      format: formatCurrency
    });
    return <Input ref={ref} value={rifm.value} onChange={rifm.onChange} {...props} type="string" />;
  }
);

export default InputCurrency;
