import { useEffect, useState } from 'react';

import { getVendors } from 'api/vendors';
import { DropdownOption } from 'components';

type Vendors = {
  attorneys: Vendor[];
  lenders: Vendor[];
};

const getSelectOptions = (data: Vendor[]): DropdownOption[] => {
  const selectOptions = data
    .map((item: Vendor) => ({
      value: item.id,
      text: item.name || item.email
    }))
    .sort((a: DropdownOption, b: DropdownOption) => a.text - b.text);

  return selectOptions;
};

export default () => {
  const [vendors, setVendors] = useState<Vendors>({
    attorneys: [],
    lenders: []
  });

  const fetchVendors = async () => {
    try {
      const vendors = await getVendors();
      const attorneys = vendors.filter(vendor => vendor.category === 'Attorney');
      const lenders = vendors.filter(vendor => vendor.category === 'Lender');
      setVendors({ attorneys, lenders });
    } catch (err) {
      console.error(err.message);
    }
  };

  const getSelectedVendor = (vendorId: number, type: 'attorneys' | 'lenders') => {
    return vendors[type].find(vendor => vendor.id === vendorId);
  };

  const getSelectVendorOptions = (type: 'attorneys' | 'lenders', includeOther?: boolean) => {
    const vendorSelectedOptions = getSelectOptions(vendors[type]);

    return includeOther
      ? [
          ...vendorSelectedOptions,
          {
            value: 'Other',
            text: 'Other'
          }
        ]
      : vendorSelectedOptions;
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  return {
    attorneys: vendors.attorneys,
    lenders: vendors.lenders,
    getSelectedVendor,
    getSelectVendorOptions
  };
};
