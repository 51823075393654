import React, { useState, useMemo } from 'react';
import dayjs from 'dayjs';
import DayPicker, { DateUtils } from 'react-day-picker';

import styled from '@emotion/styled';
import { Icon, Button, Modal } from 'components';

const CustomNavbar = ({ onPreviousClick, onNextClick }) => {
  return (
    <StyledCustomNavbar className="navbar">
      <button onClick={() => onPreviousClick()} data-cy="go_to_prev_month">
        <Icon name="arrow-simple" size={16} />
      </button>
      <button onClick={() => onNextClick()} data-cy="go_to_next_month">
        <Icon name="arrow-simple" size={16} />
      </button>
    </StyledCustomNavbar>
  );
};

const SelectDates = ({ onSubmit, onClose, start, end, ...props }) => {
  const initialState = {
    from: start || new Date(),
    to: end || new Date()
  };

  const [selectedDays, setSelectedDays] = useState(initialState);
  const { from, to } = selectedDays;
  const modifiers = { start: from, end: to };

  const handleDayClick = day => {
    const range = DateUtils.addDayToRange(day, { from, to: to || day });
    setSelectedDays({ from: range.from, to: range.to });
  };

  const handleClearSelection = () => {
    setSelectedDays({ from: null, to: null });
    onSubmit({ start: null, end: null });
    onClose();
  };

  const handleSubmitButtonClick = () => {
    onSubmit(modifiers);
    onClose();
  };

  const disabledDays = useMemo(() => {
    return { after: new Date() };
  }, []);

  const initialMonth = to || new Date();

  return (
    <StyledModal
      modalTitle="Select dates"
      onClose={onClose}
      {...props}>
      <StyledDayPicker
        navbarElement={CustomNavbar}
        modifiers={modifiers}
        onDayClick={handleDayClick}
        disabledDays={disabledDays}
        showOutsideDays
        initialMonth={initialMonth}
        captionElement={({ date }) => (
          <div
            className="DayPicker-Caption"
            data-cy={`month_${date.getMonth() + 1}_${date.getFullYear()}`}>
            {dayjs(date).format('MMMM YYYY')}
          </div>
        )}
        selectedDays={[from, { from, to }]}
      />
      {(from || to) && (
        <Button
          secondary
          className="clear-btn"
          onClick={handleClearSelection}
          data-cy="custom-date_clear">
          <span>↺</span> Clear Selection
        </Button>
      )}
      <Button
        disabled={!from || !to}
        onClick={handleSubmitButtonClick}
        data-cy="custom-date_submit">
        Show for this period
      </Button>
    </StyledModal>
  );
};

export default SelectDates;

const StyledDayPicker = styled(DayPicker)`
  display: inline-block;

  .DayPicker-wrapper {
    display: inline-block;
    position: relative;

    .DayPicker-Months {
      display: flex;

      .DayPicker-Month {
        user-select: none;

        &:nth-of-type(2) {
          margin-left: 80px;
        }

        .DayPicker-Caption {
          text-align: left;
          margin-bottom: 12px;
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
        }

        .DayPicker-Weekdays {
          border-bottom: 1px solid #dadada;
          padding: 10px 0;
          margin-bottom: 23px;

          .DayPicker-WeekdaysRow {
            display: flex;
            justify-content: space-between;

            .DayPicker-Weekday {
              font-size: 12px;
              line-height: 30px;
              width: 30px;
              color: ${props => props.theme.colors.grayCarbon};
              text-align: center;
              font-size: 0.875em;

              &:last-child {
                margin-right: 0;
              }

              abbr[title] {
                border-bottom: none;
                text-decoration: none;
              }
            }
          }
        }

        .DayPicker-Body {
          .DayPicker-Week {
            display: flex;
            margin: 20px 0;
            justify-content: space-between;

            &:first-of-type {
              margin-top: 0;
            }
            &:last-child {
              margin-bottom: 0;
            }

            .DayPicker-Day {
              margin-right: 15px;
              flex-shrink: 0;
              width: 30px;
              height: 30px;
              font-size: 12px;
              line-height: 30px;
              box-sizing: border-box;
              text-align: center;
              cursor: pointer;
              border-radius: ${props => props.theme.misc.borderRadius};

              &:focus {
                outline: none;
              }

              &.DayPicker-Day--outside,
              &.DayPicker-Day--disabled {
                color: ${props => props.theme.colors.gray};
              }

              &.DayPicker-Day--disabled {
                pointer-events: none;
              }

              &.DayPicker-Day--today {
                color: ${props => props.theme.colors.red};
                font-weight: 700;
              }

              &.DayPicker-Day--start,
              &.DayPicker-Day--end {
                color: white;
                background: ${props => props.theme.colors.red};
              }

              &:last-child {
                margin-right: 0;
              }

              &.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
                background: ${props => props.theme.colors.seashell};
                color: #000;

                position: relative;
                z-index: 1;

                &::before {
                  content: '';
                  position: absolute;

                  width: 60px;
                  height: 100%;
                  left: -15px;
                  top: 0;
                  z-index: -1;
                }

                &:first-of-type {
                  &::before {
                    width: 45px;
                    left: 0;
                  }
                }

                &:last-child {
                  &::before {
                    width: 45px;
                  }
                }
              }

              &.DayPicker-Day--selected:not(.DayPicker-Day--start) {
                &::before {
                  content: '';
                  position: absolute;
                  background: ${props => props.theme.colors.seashell};
                  height: 100%;
                  right: 15px;
                  top: 0;
                  z-index: -1;
                }
              }
            }
          }
        }
      }
    }
  }


`;

const StyledCustomNavbar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  button {
    padding: 8px;
    border: none;
    background: none;

    &:nth-of-type(2) .icon {
      transform: rotate(180deg);
    }
  }
`;

const StyledModal = styled(Modal)`
  .modal-head {
    .title {
      & > span {
        font-size: 14px;
        color: ${props => props.theme.colors.grayDark};
      }
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .DayPicker {
    margin-bottom: 30px;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      margin: 0 auto 30px auto;
    }
  }

  .button ~ .button {
    margin-top: 12px;
  }
  .button {
    width: 100%;
  }
`;
