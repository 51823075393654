/* eslint-disable camelcase */
import React, { useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from '@emotion/styled';

import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { Button, Checkbox, Dropdown, Input } from '@xchange/uikit';
import { InputCurrency, InputPercentage } from 'components';
import { validateInputPercentage } from 'utils/validation';
import { uploadDocument } from 'api/documents';

export type CommissionsData = {
  sponsoring_broker_fee: number;
  technology_fee: number;
  desk_fee: number;
  transaction_coordination_fee: number;
  risk_management_fee: number;
  sell_agreements_fee: number;
  lease_agreements_fee: number;
  draft: boolean;
  doctypeName: string;
  file: File;
};
interface MemberCommissionsFormProps {
  data?: Partial<CommissionsData>;
  userId: number;
  onSubmit: (values: Partial<CompanyData>) => void;
  extended?: boolean;
}

const contractTypeOptions = [
  { value: 'Employment contract', text: 'Employment contract' },
  { value: 'Broker license', text: 'Broker license' },
  { value: 'Other', text: 'Other' }
];

const MemberCommissionsForm: React.FC<MemberCommissionsFormProps> = ({
  data,
  userId,
  onSubmit,
  extended
}) => {
  const {
    control,
    register,
    formState: { errors },
    formState,
    setValue,
    clearErrors,
    handleSubmit,
  } = useForm({
    defaultValues: data
  });
  const [file, setFile] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInputChange = async e => {
    const file: File = e.target.files[0];
    if (!file) return;
    fileInputRef.current!.value = '';

    setValue('file', file.name);
    setFile(file);
    clearErrors('file');
  };

  const submit = async values => {
    if (!file) {
      return onSubmit(values);
    }
    try {
      await uploadDocument({
        file: file!,
        doctypeName: values.doctypeName,
        draft: values.draft,
        publicAccess: true,
        userId
      });
      onSubmit(values);
    } catch (err) {
      const errorMessage = tryGetFirstError(err);
      notify(errorMessage);
    }
  };

  return (
    <StyledMemberCommissionsForm
      className="commissions-form"
      onSubmit={handleSubmit(submit)}
    >
      {extended && (
        <>
          <Input
            {...register('file', { required: 'Required' })}
            label="Select file"
            icon="fc-file"
            placeholder="Select file"
            onClick={() => fileInputRef.current!.click()}
            error={errors.file?.message}
            readOnly
            className="commissions-form__input"
          />
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileInputChange}
            style={{ display: 'none' }}
          />
          <Controller
            control={control}
            rules={{ required: 'Required' }}
            name="doctypeName"
            defaultValue=""
            render={({ field, formState: { errors } }) => (
              <Dropdown
                options={contractTypeOptions}
                error={errors.doctypeName?.message}
                label="Type"
                placeholder="Select type"
                className="commissions-form__input"
                {...field}
              />
            )}
          />
        </>
      )}
      <div>
        <Controller
          control={control}
          name="sponsoring_broker_fee"
          defaultValue={0}
          render={({ field, formState: { errors } }) => (
            <InputCurrency
              label="Monthly Sponsoring Broker Fee"
              placeholder="Enter amount"
              error={errors.sponsoring_broker_fee?.message}
              className="commissions-form__input"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="technology_fee"
          defaultValue={0}
          render={({ field, formState: { errors } }) => (
            <InputCurrency
              label="Monthly Technology Fee"
              placeholder="Enter amount"
              error={errors.technology_fee?.message}
              className="commissions-form__input"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="desk_fee"
          defaultValue={0}
          render={({ field, formState: { errors } }) => (
            <InputCurrency
              label="Monthly Desk Fee"
              placeholder="Enter amount"
              error={errors.desk_fee?.message}
              className="commissions-form__input"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          rules={{
            validate: validateInputPercentage
          }}
          name="sell_agreements_fee"
          defaultValue={0}
          render={({ field, formState: { errors } }) => (
            <InputPercentage
              label="Per-Transaction Sell Agreements Fee"
              placeholder="Enter percentage"
              error={errors.sell_agreements_fee?.message}
              className="commissions-form__input"
              {...field}
            />
          )}
        />
      </div>
      <div>
        <Controller
          control={control}
          rules={{
            validate: validateInputPercentage
          }}
          name="lease_agreements_fee"
          defaultValue={0}
          render={({ field, formState: { errors } }) => (
            <InputPercentage
              label="Per-Transaction Lease Agreements Fee"
              placeholder="Enter percentage"
              error={errors.lease_agreements_fee?.message}
              className="commissions-form__input"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="transaction_coordination_fee"
          defaultValue={0}
          render={({ field, formState: { errors } }) => (
            <InputCurrency
              label="Per-Transaction Coordination Fee"
              placeholder="Enter amount"
              error={errors.transaction_coordination_fee?.message}
              className="commissions-form__input"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="risk_management_fee"
          defaultValue={0}
          render={({ field, formState: { errors } }) => (
            <InputCurrency
              label="Per-Transaction Risk-Management Fee"
              placeholder="Enter amount"
              error={errors.risk_management_fee?.message}
              className="commissions-form__input"
              {...field}
            />
          )}
        />
      </div>
      {extended && (
        <Checkbox
          {...register('draft')}
          label="Draft"
          className="commissions-form__input"
        />
      )}
      <Button disabled={formState.isSubmitting} className="commissions-form__btn">
        Save
      </Button>
    </StyledMemberCommissionsForm>
  );
};

export default React.memo(MemberCommissionsForm);

const StyledMemberCommissionsForm = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
  .commissions-form {
    &__input {
      width: 100%;
      margin: 8px 0;
    }
    &__btn {
      margin-top: 8px;
      margin-left: auto;
      grid-column: span 2;
      padding: 0 33px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
    .button {
      margin: 16px 0 0;
      width: 100%;
    }
  }
`;
