import React, { useCallback, useEffect, useRef } from 'react';
import Input, { InputProps } from 'components/Input';
import useCombinedRefs from 'hooks/useCombineRefs';

// let autoComplete: google.maps.places.Autocomplete;

interface InputPlacesProps extends InputProps {
  options?: google.maps.places.AutocompleteOptions;
  onPlaceChange?: (query: string | undefined, place: google.maps.places.PlaceResult) => void;
}

type BoundsType = { southWest: [number, number]; northEast: [number, number] };
type LatLngBounds = google.maps.LatLngBounds;

export const BOUNDS_ILLINOIS: BoundsType = {
  southWest: [36.966, -91.502],
  northEast: [42.511, -87.5]
};

export const getBounds = (bounds: BoundsType): LatLngBounds => {
  const {
    southWest: [south, west],
    northEast: [north, east]
  } = bounds;
  const southWest = new window.google.maps.LatLng(south, west);
  const northEast = new window.google.maps.LatLng(north, east);
  const _bounds = new google.maps.LatLngBounds(southWest, northEast);
  return _bounds;
};

const InputPlaces: React.FC<InputPlacesProps> = React.forwardRef(
  ({ options, onChange, onPlaceChange, value, ...props }, forwardRef) => {
    const innerRef = useRef(null);
    const ref = useCombinedRefs<HTMLInputElement>(innerRef, forwardRef);
    const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

    const handlePlaceSelect = useCallback(async () => {
      const autocomplete = autocompleteRef.current;
      if (!autocomplete) return;
      const place = autocomplete.getPlace();
      const query = place?.formatted_address || ref.current?.value;
      onPlaceChange?.(query, place);
    }, [ref, onPlaceChange]);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') e.preventDefault();
    };

    useEffect(() => {
      // if (autoComplete !== undefined) return;
      if (!window.google.maps?.places) {
        // eslint-disable-next-line no-console
        console.error('Places api is not loaded');
        return;
      }
      if (autocompleteRef.current) return;

      const bounds = getBounds(BOUNDS_ILLINOIS);

      const opts: google.maps.places.AutocompleteOptions = {
        types: ['address'],
        componentRestrictions: { country: 'us' },
        bounds,
        ...options
      };
      autocompleteRef.current = new window.google.maps.places.Autocomplete(ref.current!, opts);
      autocompleteRef.current.setFields(['address_components', 'formatted_address', 'geometry']);
      autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
    }, [handlePlaceSelect, ref, options]);

    return (
      <Input
        ref={ref}
        defaultValue={value}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        {...props}
      />
    );
  }
);

export default InputPlaces;
