import React from 'react';
import clsx from 'clsx';

import styled from '@emotion/styled';
import Button from 'components/Button';
import Modal, { ModalProps } from 'components/Modal';
import { Dropdown, DropdownOption, Checkbox } from 'components';
import { useForm, Controller } from 'react-hook-form';
import useUserVendors from 'hooks/useUserVendors';

interface ModalExecuteOfferProps extends ModalProps {
  onSubmit: () => Promise<[]>;
  showAttorneyDropdown: boolean;
  setShowAttorneyDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  members: any;
}

const ModalExecuteOffer: React.FC<ModalExecuteOfferProps> = ({
  onSubmit,
  onClose,
  showAttorneyDropdown,
  setShowAttorneyDropdown,
  members,
  open,
  className,
  children,
  ...props
}) => {

  const { control, handleSubmit } = useForm();

  const options: DropdownOption[] = [...Array(20).keys()].map(item => ({
    value: item++,
    text: item++
  }));

  const {
    getSelectVendorOptions
  } = useUserVendors();

  const attorneySelectOptions = getSelectVendorOptions('attorneys', false);

  const group = {};
  group['SellerAttorney'] = members.filter(member => member.role?.includes('SellerAttorney'));

  return (
    <StyledModalExecuteOffer
      className={clsx('confirm', className)}
      onClose={onClose}
      open={open}
      {...props}>
      {children}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="fillSellSideData"
          defaultValue={false}
          render={({ field: { onChange, ...field }, formState: { errors } }) => (
            <Checkbox
              label={"I want to prefill the sale side vendor information."}
              onChange={e => {
                onChange(e.target.checked);
                if(group['SellerAttorney'].length == 0){
                  setShowAttorneyDropdown(e.target.checked)}
                }
              }
              className="sale-side__checkbox"
              {...field}
            />
          )}
        />
        {showAttorneyDropdown && ( 
          <Controller
            control={control}
            name="AttorneyId"
            rules={{ required: 'Required' }}
            render={({ field: { onChange, ...field }, formState: { errors } }) => (
              <Dropdown
                options={attorneySelectOptions}
                onChange={value => {
                  onChange(value);
                }}
                label="Select Attorney"
                placeholder="Select Attorney"
                className="attorney__input"
                error={errors.AttorneyId?.message}
                {...field}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="page"
          rules={{ required: 'Required' }}
          defaultValue={0}
          render={({ field, formState: { errors } }) => (
            <Dropdown
              className="dropdown-wide"
              label="At which page does the Offer Agreement start?"
              placeholder="Select"
              error={errors.page?.message}
              options={options}
              {...field}
            />
          )}
        />
        <Button type="submit" large>Submit</Button>
      </form>
    </StyledModalExecuteOffer>
  );
};

export default ModalExecuteOffer;

const StyledModalExecuteOffer = styled(Modal)`
  .modal-content {
    min-width: 300px;
    max-width: 300px;
    font-size: 16px;
    line-height: 24px;
  }

  .modal-title {
    padding: 12px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .modal-body {
    p {
      font-size: 14px;
    }
  }

  button.large, .dropdown-wide {
    width: 100%;
    margin-top: 16px;
  }

  .dropdown-wide > label, .attorney__input > label {
    font-size: 12px;
  }

  .sale-side__checkbox {
    margin-bottom: 16px;
  }
`;
