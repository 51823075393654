import React from 'react';
import styled from '@emotion/styled';
import useSitedataAssets from 'hooks/useSitedataAssets';
import { ThemeWrapper } from 'styles';

const HeaderOnlyLayout = ({ children, ...props }) => {
  const { logo } = useSitedataAssets();

  return (
    <ThemeWrapper>
      <StyledHeaderOnlyLayout {...props}>
        <header>{logo && <img src={logo} alt="logo" />}</header>
        <div className="content">{children}</div>
      </StyledHeaderOnlyLayout>
    </ThemeWrapper>
  );
};

export default HeaderOnlyLayout;

const StyledHeaderOnlyLayout = styled.div`
  width: 80%;
  margin: auto;

  & > header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 77px;
    border-bottom: 1px solid ${props => props.theme.colors.grayDark};
  }
  header > img {
    max-width: 150px;
  }
`;
