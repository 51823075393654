import React from 'react';

import Input, { InputProps } from 'components/Input';
import { formatCurrency } from 'utils/rifmFormatters';

interface CurrencyInputProps extends Omit<InputProps, 'onChange'> {
  value?: string;
  onChange: (value: string | '') => void;
}

const InputCurrencyDecimal: React.FC<CurrencyInputProps> = React.forwardRef(
  ({ value = '', onChange, ...props }, ref) => {

    value = '$'+String(value).replace('$', '');
    value === '$' ? value = '' : value = value;
    value = formatCurrency(value);

    return <Input ref={ref} value={String(value)} onChange={value => onChange(value)} {...props} type="string" />;
  }
);

export default InputCurrencyDecimal;
