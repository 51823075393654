import React from 'react';
import { ControllerProps, Control, useController } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import Input, { InputProps } from 'components/Input';

interface MaskInputControllerProps
  extends Omit<InputProps, 'defaultValue'>,
    Omit<ControllerProps, 'render'> {
  control: Control<any>;
  mask: string;
  name: string;
}

const MaskInputController: React.FC<MaskInputControllerProps> = ({
  control,
  name,
  mask,
  rules,
  defaultValue = '',
  ...props
}) => {
  const { field } = useController({ name, control, rules, defaultValue });
  const maskProps = { as: MaskedInput, mask };

  return (
    <Input
      label="Office Line"
      placeholder="Enter Office Line"
      {...props}
      {...maskProps}
      {...field}
    />
  );
};

export default MaskInputController;
